var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"lg":"9","md":"9","sm":"9","xs":"9"}},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"pa-10 pt-5 pb-0"},[_vm._l((_vm.listNews),function(news,index){return _c('v-row',{key:index},[(news.stt == 1)?_c('v-col',{attrs:{"lg":"8","md":"8","sm":"8","xs":"8"}},[_c('router-link',{attrs:{"to":{ name: 'view-detail-news', params: { newsID: _vm.newestID } }}},[_c('img',{staticStyle:{"object-fit":"cover","aspect-ratio":"16/9"},attrs:{"src":'data:image/jpeg;base64,' + _vm.newestContentImage,"width":"100%"}})]),_c('p',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm._dayjs(_vm.newestCreatedDate).format('DD/MM/YYYY'))+" by "+_vm._s(_vm.newestCreatedBy)+" ")]),_c('router-link',{attrs:{"to":{ name: 'view-detail-news', params: { newsID: _vm.newestID } }}},[(_vm.newestTitle.length < 200)?_c('h2',[_vm._v(" "+_vm._s(_vm.newestTitle)+" ")]):_c('h2',[_vm._v(" "+_vm._s(_vm.newestTitle.substr(0, 200) + '...')+" ")])]),(_vm.newestContentText.length < 200)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.newestContentText)}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.newestContentText.substr(0, 200) + '...')}})],1):_vm._e(),(
              news.stt == 1 &&
              (_vm.listNews.filter(function (x) { return x.stt == 2; }).length == 1 ||
                _vm.listNews.filter(function (x) { return x.stt == 3; }).length == 1)
            )?_c('v-col',{staticClass:"pr-1 pl-2",attrs:{"lg":"4","md":"4","sm":"4","xs":"4"}},[(_vm.listNews.length > 1)?_c('div',[_c('router-link',{attrs:{"to":{
                  name: 'view-detail-news',
                  params: { newsID: _vm.secondID },
                }}},[_c('img',{staticStyle:{"object-fit":"cover","aspect-ratio":"16/9"},attrs:{"src":'data:image/jpeg;base64,' + _vm.secondContentImage,"width":"100%"}})]),_c('p',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm._dayjs(_vm.secondCreatedDate).format('DD/MM/YYYY'))+" by "+_vm._s(_vm.secondCreatedBy)+" ")]),_c('router-link',{attrs:{"to":{
                  name: 'view-detail-news',
                  params: { newsID: _vm.secondID },
                }}},[(_vm.secondTitle.length < 200)?_c('h2',[_vm._v(" "+_vm._s(_vm.secondTitle)+" ")]):_c('h2',[_vm._v(_vm._s(_vm.secondTitle.substr(0, 200) + '...'))])])],1):_vm._e(),(_vm.listNews.length > 2)?_c('div',{staticClass:"pt-6"},[_c('router-link',{attrs:{"to":{
                  name: 'view-detail-news',
                  params: { newsID: _vm.thirdID },
                }}},[_c('img',{staticStyle:{"object-fit":"cover","aspect-ratio":"16/9"},attrs:{"src":'data:image/jpeg;base64,' + _vm.thirdContentImage,"width":"100%"}})]),_c('p',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm._dayjs(_vm.thirdCreatedDate).format('DD/MM/YYYY'))+" by "+_vm._s(_vm.thirdCreatedBy)+" ")]),_c('router-link',{attrs:{"to":{
                  name: 'view-detail-news',
                  params: { newsID: _vm.thirdID },
                }}},[(_vm.thirdTitle.length < 200)?_c('h2',[_vm._v(" "+_vm._s(_vm.thirdTitle)+" ")]):_c('h2',[_vm._v(_vm._s(_vm.thirdTitle))])])],1):_vm._e()]):_vm._e()],1)}),_c('v-row',_vm._l((_vm.listNews),function(news,index){return _c('v-col',{key:index,staticClass:"pr-1 pl-2",attrs:{"lg":"4","md":"4","sm":"4","xs":"4"}},[(news.stt > 3)?_c('div',{staticClass:"pt-6"},[_c('router-link',{attrs:{"to":{
                  name: 'view-detail-news',
                  params: { newsID: news.newsID },
                }}},[_c('img',{staticStyle:{"object-fit":"cover","aspect-ratio":"16/9"},attrs:{"src":'data:image/jpeg;base64,' + news.contentImage,"width":"100%"}})]),_c('p',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm._dayjs(news.crtdDate).format('DD/MM/YYYY'))+" by "+_vm._s(news.crtdUser)+" ")]),_c('router-link',{attrs:{"to":{
                  name: 'view-detail-news',
                  params: { newsID: news.newsID },
                }}},[(news.title.length < 200)?_c('h2',[_vm._v(" "+_vm._s(news.title)+" ")]):_c('h2',[_vm._v(" "+_vm._s(news.title.substr(0, 200) + '...')+" ")])])],1):_vm._e()])}),1)],2):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"elevation":"0"},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }